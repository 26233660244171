import React, { useEffect, useState, useRef } from "react"
import { graphql, Link } from "gatsby"
import ReactMarkdown from "react-markdown"
import SEO from "../components/seo"

import {
  Footer,
  Container,
  Section,
  Header,
  Icon,
  Button,
  App_ApplePodcast,
  App_CastBox,
  App_GooglePodcast,
  App_Overcast,
  App_Spotify,
  ActionBarForArticle,
} from "../components/Lib"
import { toPersianNumber, slugify, toJalaliDate } from "../utils/helpers"

const EpisodePage = ({ data, pageContext }) => {
  const [showMore, setShowMore] = useState(false)
  const readingTitleRef = useRef()
  const episode = data.strapiEpisode
  const person = data.strapiEpisode.people[0]
  const relatedPosts = data.relatedPosts.edges
  const externalLinks = episode.components.filter(
    component => component?.componentType?.typeName == "externalLink"
  )
  const sponsors = episode.components.filter(
    component => component?.componentType?.typeName == "sponsor"
  )
  function handleShowMore() {
    setShowMore(prev => !prev)
    readingTitleRef.current.scrollIntoView()
  }

  return (
    <Container>
      <SEO
        title={`پادکست زندگینامه ${episode.title}، داستان و حواشی زندگی او`}
        image={{
          src: episode.squareImage.childImageSharp.fixed.src,
          width: episode.squareImage.childImageSharp.fixed.width,
          height: episode.squareImage.childImageSharp.fixed.height,
        }}
        description={episode.description}
        keywords={episode.keyword.map(item => item.title)}
      />
      <Header pushed className="header__episode_page" />
      <section className="episode_page__header">
        <div className="episode_page__header__info">
          <div className="episode_page__number">
            {toPersianNumber(episode.episodeNumber)}
          </div>
          <h1 className="episode_page__title">{episode.title}</h1>
          <h2 className="episode_page__subtitle">{episode.personTagline}</h2>
        </div>
        <svg
          className="episode_page__main_image"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 687.192 650.407"
        >
          <defs>
            <clipPath id="a">
              <path
                d="M196.536,51.445c39.985-68.594,139.026-68.594,179.011,0L557.812,364.12c40.3,69.128-9.532,155.942-89.505,155.942H103.777c-79.974,0-129.8-86.814-89.505-155.942Z"
                transform="matrix(-0.966, -0.259, 0.259, -0.966, 660.036, 801.234)"
                fill="#63a1a3"
              />
            </clipPath>
            <pattern
              id="b"
              preserveAspectRatio="none"
              width="100%"
              height="100%"
            >
              <image
                preserveAspectRatio="xMidYMid slice"
                width="100%"
                href={episode.squareImage.childImageSharp.fluid.src}
              />
            </pattern>
          </defs>
          <g transform="translate(-107.446 -150.827)" clipPath="url(#a)">
            <rect
              width="572.708"
              height="809.929"
              transform="translate(204.464 70.934)"
              fill="url(#b)"
            />
          </g>
        </svg>
      </section>
      <Section className="episode_page__listen rounded-3">
        <div className="episode_page__wrapper">
          <Heading title="بشنویم" icon="headphones" />
          <p className="episode_page__listen__description">
            {episode.description}
          </p>
          {externalLinks && (
            <div className="episode_page__links">
              {externalLinks.map(link => (
                <ExternalLink key={link.url} link={link} />
              ))}
            </div>
          )}
          <div
            className="episode_page__player"
            dangerouslySetInnerHTML={{ __html: episode.podbeanEmbedCode }}
          />
          <div className="episode_page__apps">
            <div className="episode_page__apps__title">از کجا بشنویم؟</div>
            <div className="episode_page__apps__apps">
              {episode.spotifyLink && (
                <a
                  href={episode.spotifyLink}
                  className="navigator"
                  target="_blank"
                >
                  <App_Spotify />
                </a>
              )}
              {episode.applePodcastLink && (
                <a
                  href={episode.applePodcastLink}
                  className="navigator"
                  target="_blank"
                >
                  <App_ApplePodcast />
                </a>
              )}
              {episode.castboxLink && (
                <a
                  href={episode.castboxLink}
                  className="navigator"
                  target="_blank"
                >
                  <App_CastBox />
                </a>
              )}
              {episode.googlePodcastLink && (
                <a
                  href={episode.googlePodcastLink}
                  className="navigator"
                  target="_blank"
                >
                  <App_GooglePodcast />
                </a>
              )}
              {episode.overcastLink && (
                <a
                  href={episode.overcastLink}
                  className="navigator"
                  target="_blank"
                >
                  <App_Overcast />
                </a>
              )}
            </div>
          </div>
          {sponsors &&
            sponsors.map(sponsor => (
              <Sponsor key={sponsor.name} sponsor={sponsor} />
            ))}
        </div>
      </Section>
      <Section
        className="episode_page__read"
        padding={{ right: "0", left: "0" }}
        ref={readingTitleRef}
      >
        <div
          className={`episode_page__wrapper${showMore ? " fixed-heading" : ""}`}
        >
          <Heading title="بخوانیم" icon="book_open" color="secondary" />
        </div>
        {episode.text && (
          <>
            <div>
              <article
                className={`article__content article__content--close${
                  showMore ? " article__content--open" : ""
                }`}
              >
                <ReactMarkdown
                  source={episode.fields.convertedText}
                  escapeHtml={false}
                  transformImageUri={uri =>
                    uri.startsWith("http")
                      ? uri
                      : `${process.env.IMAGE_BASE_URL}${uri}`
                  }
                />
              </article>
              {!showMore && (
                <div className="article__show_more">
                  <Button color="primary" onClick={handleShowMore}>
                    مطالعه بیشتر
                  </Button>
                </div>
              )}
            </div>
          </>
        )}
      </Section>
      {relatedPosts.length > 0 && (
        <Section class="top-rounded-3" backgroundColor="var(--lightgray)">
          <Heading title="نوشته‌های مرتبط" icon="file_text" color="gray" />
          <div className="episode_page__posts__links">
            {relatedPosts.slice(0, 5).map(({ node: post }) => (
              <Link
                key={post.title}
                className="link episode_page__posts__link"
                to={`/blog/${slugify(post.title)}`}
              >
                <span>{post.title}</span> ✽{" "}
                <span>{toJalaliDate(new Date(post.created_at))}</span>
              </Link>
            ))}
            {relatedPosts.length > 0 && (
              <Button
                className="episode_page__posts__button"
                Tag="Link"
                to={`/blog/tag/${slugify(person.shownName)}`}
              >
                مشاهده همه نوشته‌های مرتبط با {person.shownName}
              </Button>
            )}
          </div>
        </Section>
      )}
      <Footer />
      <ActionBarForArticle
        showMore={showMore}
        setShowLess={() => {
          setShowMore(false)
          {
            readingTitleRef.current.scrollIntoView()
          }
        }}
      />
    </Container>
  )
}

export const query = graphql`
  query($slug: String!, $people: [Int]!) {
    strapiEpisode(fields: { slug: { eq: $slug } }) {
      applePodcastLink
      castboxLink
      created_at
      episodeNumber
      googlePodcastLink
      description

      namlikLink
      overcastLink
      shenotoLink
      spotifyLink
      stitcherLink
      text
      title
      podbeanEmbedCode
      personTagline
      people {
        shownName
      }
      keyword {
        title
      }
      fields {
        slug
        convertedText
      }
      components {
        url
        title
        name
        link
        svgLogo
        description
        componentType {
          typeName
        }
      }
      squareImage {
        childImageSharp {
          fluid(quality: 100) {
            src
          }
          fixed {
            src
            width
            height
          }
        }
      }
    }
    relatedPosts: allStrapiPost(
      filter: { people: { elemMatch: { id: { in: $people } } } }
    ) {
      edges {
        node {
          title
          created_at
        }
      }
    }
  }
`

const ExternalLink = ({ link }) => {
  return (
    <a
      className="external_link"
      href={link.url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon name="external_link" />
      <span>{link.title}</span>
    </a>
  )
}

const Sponsor = ({ sponsor }) => {
  return (
    <a
      href={sponsor.link}
      className="episode_page__sponsor navigator"
      target="_blank"
      rel="sponsored"
    >
      <header>
        <div className="episode_page__sponsor__title">{sponsor.title}</div>
        {sponsor.svgLogo ? (
          <div
            className="episode_page__sponsor__logo"
            dangerouslySetInnerHTML={{ __html: sponsor.svgLogo }}
          />
        ) : (
          <div className="episode_page__sponsor__name">{sponsor.name}</div>
        )}
      </header>
      {sponsor.description && (
        <p className="episode_page__sponsor__description">
          {sponsor.description}
        </p>
      )}
    </a>
  )
}

export const Heading = React.forwardRef(
  ({ title, icon, color = "primary", ...props }, ref) => {
    return (
      <header
        {...props}
        ref={ref}
        className="episode_page__heading"
        style={{ color: `var(--${color})` }}
      >
        <div className="episode_page__heading__wrapper">
          <Icon name={icon} />
          <h4>{title}</h4>
        </div>
      </header>
    )
  }
)

export default EpisodePage
